import { Outlet } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import {
  Navbar,
  SuccessModal,
  LoadingModal,
  ErrorModal,
  RadialMenu,
  PickACarModal,
  Regulations,
} from "./components";

import s from "./app.module.scss";

import { Footer } from "./Layout/Footer";
const queryClient = new QueryClient();

function App({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <div className={s.main_container}>
        <SuccessModal />
        <ErrorModal />
        <LoadingModal />
        <RadialMenu />
        <PickACarModal />
        <Regulations />
        <Navbar />
        <div className={s.homeBackground}>
          <div className={s.backgroundMobile}></div>
        </div>
        <div className={s.homeBackground}>
          <div className={s.backgroundLaptop}></div>
        </div>
        <div className={s.main_section}>
          {children}
          {/* <Outlet /> */}
        </div>
        <Footer />
      </div>
    </QueryClientProvider>
  );
}

export default App;
