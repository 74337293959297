import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "../../../../utils/store";

import InShop from "./InShop";
import Waiting from "./Waiting";
import Editing from "./EditService";
import UserServiceHistory from "../UserServiceHistory/index";
import { getServicesOptions } from "../getServicesQueryOptions";
import { getAllServices } from "../../../../requests/service-request";

import { ReactComponent as CarQueue } from "../../../../svgs/wait2.svg";
import { ReactComponent as InService } from "../../../../svgs/car-shop.svg";
import { ReactComponent as Done } from "../../../../svgs/accept.svg";
import { ReactComponent as Plus } from "../../../../svgs/plus-svgrepo-com.svg";

import "./index.scss";

export default function ServiceHistory({ fastServiceEdit }) {
  const [activeTab, setActiveTab] = useState(fastServiceEdit || "closed");
  const [serviceItem, setServiceItem] = useState(undefined);
  const [serviceHistoryFetched, setServiceHistoryFetched] = useState(false);
  const [
    {
      history,
      user: { role, _id },
    },
    dispatch,
  ] = useStore();
  const firstFound = history.find(
    (item) => item.shopApproved && !item.archived
  );
  const navigate = useNavigate();

  const { mutate: getServicesQuery } = useMutation(
    getAllServices,
    getServicesOptions({ navigate, dispatch, setServiceHistoryFetched })
  );

  useEffect(() => {
    if (!serviceHistoryFetched) {
      getServicesQuery(
        role === "USER"
          ? {
              user: _id,
              to: "/account/garage/user-service-history",
              replace: true,
            }
          : { shop: _id }
      );
    }
    //eslint-disable-next-line
  }, []);

  return serviceHistoryFetched ? (
    <div className="service-history-container">
      <div className="service-history-tab-section">
        {role === "SERVICE" && (
          <div
            onClick={() => navigate("/account/garage/add-car-as-service")}
            className={`all-services-done service-history-tab ${
              activeTab === "closed" ? "active" : ""
            }`}
          >
            <Plus />
            <span>Dodaj serwis auta klienta</span>
          </div>
        )}

        <div
          onClick={() => setActiveTab("waiting")}
          className={`service-history-tab ${
            activeTab === "waiting" ? "active" : ""
          }`}
        >
          <CarQueue />
          <span>POCZEKALNIA</span>
        </div>
        <div
          onClick={() => setActiveTab("inshop")}
          className={`service-history-tab ${
            activeTab === "inshop" ? "active" : ""
          }`}
        >
          <InService />
          <span>W&nbsp;NAPRAWIE</span>
        </div>

        {role === "USER" && (
          <div
            onClick={() => setActiveTab("closed")}
            className={`all-services-done service-history-tab ${
              activeTab === "closed" ? "active" : ""
            }`}
          >
            <Done />
            <span>MOJE&nbsp;NAPRAWY</span>
          </div>
        )}
      </div>
      {activeTab === "waiting" ? (
        <Waiting />
      ) : activeTab === "editing" ? (
        <Editing
          onServiceItemChange={(item) => setServiceItem(item)}
          serviceItem={serviceItem || firstFound || undefined}
        />
      ) : activeTab === "closed" ? (
        <UserServiceHistory fromService={true} />
      ) : (
        <InShop
          onServiceItemChange={(item) => setServiceItem(item)}
          onTabChange={() => setActiveTab("editing")}
        />
      )}
    </div>
  ) : null;
}
