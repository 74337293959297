import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "../../../../utils/store";
import { getAllServices } from "../../../../requests/service-request";
import { ReactComponent as CarLogo } from "../../../../svgs/porsche.svg";

import "./index.scss";
import { getCarsRequest } from "../../../../requests/car-request";
import { getCarsOptions } from "../getCarsQueryOptions";

export default function MyCars() {
  const navigate = useNavigate();
  const [{ cars, user, history }, dispatch] = useStore();

  const { mutate: getCarsRequestMutate } = useMutation(
    getCarsRequest,
    getCarsOptions({ navigate, dispatch })
  );

  const { mutate } = useMutation(getAllServices, {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
    },
    onSuccess: ({ data }, variables) => {
      dispatch({ type: "updateHistory", payload: data });
      navigate("/account/garage/user-service-history", {
        state: { car: variables.carID },
      });
    },
    onError: (error) => {
      dispatch({ type: "updateHistory", payload: [] });
      dispatch({ type: "onError", payload: error });
      navigate("/response");
    },
  });

  useEffect(() => {
    getCarsRequestMutate();
  }, [getCarsRequestMutate]);

  async function handlePaySubscriptionForCar(carId) {
    const response = await fetch(`/api/payment/${carId}`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
    });
    //todo: Handle error
    const { data: redirectUrl } = await response.json();
    window.location.href = redirectUrl;
  }

  function hasActiveSubscription(car) {
    return car.subscriptions.some(
      ({ activated, endDate }) => activated && new Date(endDate) > new Date()
    );
  }
  function onCarClick(car, serviceHistory) {
    if (!serviceHistory.length) mutate({ user: user._id, carID: car._id });
    if (hasActiveSubscription(car))
      navigate("/account/garage/user-service-history", {
        state: { car: car._id },
      });
    return false;
  }
  //Todo fetch cars on component's lifecycle begining instead of fetching them when "Moje auta" button is clicked
  return cars.length ? (
    <div className="my-cars-container">
      {cars.map((car, index) => (
        <div className="car-item-container" key={car._id}>
          <div className="car-item-wrapper">
            <div
              className={`car-item ${
                !hasActiveSubscription(car) ? "car-item-overlay" : ""
              }`}
              key={index}
              onClick={() => {
                onCarClick(car, history);
                // !history.length
                //   ? mutate({ user: user._id, carID: car._id })
                //   : navigate("/account/garage/user-service-history", {
                //       state: { car: car._id },
                //     });
              }}
            >
              <div className="car-logo">
                <span>{car.year}</span>
                <CarLogo />
              </div>
              <span className="car-name">
                {car.brand} {car.model}
              </span>
              <span className="registration">{car.registration}</span>
            </div>
            {!hasActiveSubscription(car) && (
              <button
                className="car-item-button"
                onClick={() => handlePaySubscriptionForCar(car._id)}
              >
                Opłać subskrypcję
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className="my-cars-container empty">
      <span>Nie masz jeszcze żadnych aut</span>
    </div>
  );
}
