import React from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "../../../utils/store";
import { getAllUsersRequest } from "../../../requests/admin-request";
import { getAllUsersOptions } from "./getAllUsersQueryOptions";
import { ReactComponent as AddCar } from "../../../svgs/add-car.svg";
import { ReactComponent as AddRepair } from "../../../svgs/add-repair.svg";
import { ReactComponent as Repairs } from "../../../svgs/repairs.svg";
import { ReactComponent as History } from "../../../svgs/history.svg";
import { ReactComponent as MyCars } from "../../../svgs/mycars.svg";
import { ReactComponent as MyShop } from "../../../svgs/car-shop.svg";

import { default as s } from "./index.module.scss";

export default function AccountGarage() {
  const navigate = useNavigate();
  const [
    {
      user: { role },
    },
    dispatch,
  ] = useStore();

  const { mutate: getAllUsersQuery } = useMutation(
    getAllUsersRequest,
    getAllUsersOptions({
      navigate,
      dispatch,
      redirect: "garage/admin/accounts",
    })
  );

  return (
    <div className={s["garage-container"]}>
      <div
        className={`${s["tile"]}`}
        onClick={() => navigate("garage/addcar")}
        style={{ display: role === "USER" ? "grid" : "none" }}
      >
        <AddCar className={s["submenu-icon"]} />
        <span>Dodaj samochód</span>
      </div>
      <div
        className={`${s["tile"]}`}
        onClick={() => navigate("garage/service-history-fast-edit")}
        style={{ display: role === "SERVICE" ? "grid" : "none" }}
      >
        <AddRepair className={s["submenu-icon"]} />
        <span>Dodaj serwis auta</span>
      </div>
      <div
        className={` ${s["tile"]}`}
        onClick={() => navigate("garage/service-history")}
        style={{
          display: role === "SERVICE" || role === "USER" ? "grid" : "none",
        }}
      >
        <History className={s["submenu-icon"]} />
        <span>Historia serwisowa</span>
      </div>
      <div className={`${s["tile"]}`} onClick={() => navigate("garage/shops")}>
        <Repairs className={s["submenu-icon"]} />
        <span>Zaufane warsztaty</span>
      </div>
      <div
        className={`${s["tile"]}`}
        onClick={() => navigate("garage/mycars")}
        style={{ display: role === "USER" ? "grid" : "none" }}
      >
        <MyCars />
        <span>Moje auta</span>
      </div>
      <div
        className={`${s["tile"]}`}
        onClick={() => {
          //eslint-disable-next-line
          dispatch({ type: "setGlobalShop", payload: {} }),
            navigate("garage/myshop");
        }}
        style={{ display: role === "SERVICE" ? "grid" : "none" }}
      >
        <MyShop />
        <span>Mój warsztat</span>
      </div>
      <div
        className={`${s["tile"]}`}
        onClick={() => getAllUsersQuery()}
        style={{ display: role === "ADMIN" ? "grid" : "none" }}
      >
        <Repairs className={s["submenu-icon"]} />
        <span>Użytkownicy</span>
      </div>
      <div
        className={`${s["tile"]}`}
        style={{ display: role === "ADMIN" ? "grid" : "none" }}
      >
        <Repairs className={s["submenu-icon"]} />
        <span>Zatwierdzanie napraw</span>
      </div>
      <div
        className={`${s["tile"]}`}
        style={{ display: role === "ADMIN" ? "grid" : "none" }}
      >
        <Repairs className={s["submenu-icon"]} />
        <span>Inne</span>
      </div>
    </div>
  );
}
