import "./car-info-modal.scss";

export default function UserInfoModal({
  firstname,
  lastname,
  email,
  phone,
  ...props
}) {
  return (
    <div className="car-info-modal">
      <div className="car-info-modal-header">
        <span className="car-info-modal-header-title">
          Informacje o użytkowniku
        </span>
        <span className="close" onClick={() => props.handleClose()}></span>
      </div>
      <div className="car-info-modal-content">
        <span className="car-info-modal-content-info">
          Imię: {firstname.toUpperCase()}
        </span>
        <span className="car-info-modal-content-info">
          Nazwisko: {lastname.toUpperCase()}
        </span>
        <span className="car-info-modal-content-info">Email: {email}</span>
        <span className="car-info-modal-content-info">
          Telefon:{" "}
          <a className="phoneLink" href={`tel:${phone}`}>
            {phone}
          </a>
        </span>
      </div>
    </div>
  );
}
