export const CAR_BRANDS = [
  "Abarth",
  "Aixam",
  "Acura",
  "Alfa Romeo",
  "Alpine",
  "Asia",
  "Aston Martin",
  "Audi",
  "Bentley",
  "BMW",
  "BMW-Alpina",
  "Bugatti",
  "Buick",
  "Cadillac",
  "Chevrolet",
  "Chrysler",
  "Citroën",
  "Cupra",
  "Dacia",
  "Daewoo",
  "Daihatsu",
  "DeLorean",
  "DFSK",
  "Dodge",
  "Donkervoort",
  "DS Automobiles",
  "Ferrari",
  "Fiat",
  "Fisker",
  "Ford",
  "GMC",
  "Honda",
  "Hummer",
  "Hyundai",
  "Infiniti",
  "Inny",
  "Isuzu",
  "Jaguar",
  "Jeep",
  "John Deere",
  "Kaipan",
  "Kia",
  "KTM",
  "Lada",
  "Lamborghini",
  "Lancia",
  "Land Rover",
  "Landwind",
  "LEVC",
  "Lexus",
  "Ligier",
  "Lincoln",
  "Lotus",
  "LTI",
  "Luaz",
  "MAN",
  "Maserati",
  "Maxus",
  "Maybach",
  "Mazda",
  "McLaren",
  "Mercedes-Benz",
  "Mercury",
  "MG",
  "Microcar",
  "MINI",
  "Mitsubishi",
  "Morgan",
  "Nissan",
  "Nysa",
  "Oldsmobile",
  "Opel",
  "Peugeot",
  "Piaggio",
  "Plymouth",
  "Polonez",
  "Pontiac",
  "Porsche",
  "RAM",
  "Renault",
  "Rolls-Royce",
  "Rover",
  "Saab",
  "Seat",
  "Seres",
  "Skoda",
  "Skywell",
  "Smart",
  "SsangYong",
  "Subaru",
  "Suzuki",
  "Syrena",
  "Tarpan",
  "Tata",
  "Tesla",
  "Toyota",
  "Trabant",
  "Volkswagen",
  "Volvo",
  "DAF CIEZAROWE",
  "Volvo CIEZAROWE",
  "Mercedes-Benz CIEZAROWE",
  "Renault CIEZAROWE",
  "Kenworth CIEZAROWE",
  "SCANIA CIEZAROWE",
  "Iveco CIEZAROWE",
  "Mack CIEZAROWE",
  "Freightliner CIEZAROWE",
  "International CIEZAROWE",
  "Hino CIEZAROWE",
  "Isuzu CIEZAROWE",
  "FUSO CIEZAROWE",
  "MAN CIEZAROWE",
  "WesternStar CIEZAROWE",
  "TATA CIEZAROWE",
  "Ashok Leyland CIEZAROWE",
  "Eicher CIEZAROWE",
  "Freightliner CIEZAROWE",
  "UD Trucks CIEZAROWE",
  "KAMAZ CIEZAROWE",
  "ZIL CIEZAROWE",
  "GAZ CIEZAROWE",
  "KRAZ CIEZAROWE",
  "BELAZ CIEZAROWE",

];
