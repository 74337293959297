import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useStore } from "../../../../utils/store";
import { GetMonth, DayOfMonth, GetYear } from "../../../../utils/dateConverter";

import Rating from "../../../Rating";
import { ReactComponent as Shop } from "../../../../svgs/car-shop.svg";
import { ReactComponent as Auto } from "../../../../svgs/garage.svg";
import { ReactComponent as Photos } from "../../../../svgs/photos.svg";
import { ReactComponent as Text } from "../../../../svgs/text.svg";
import PhotoModal from "../ServiceHistory/PhotoModal";
import NoteModal from "../ServiceHistory/NoteModal";
import RatingModal from "./Rating-Modal";

import CarInfoModal from "./../ServiceHistory/CarInfoModal";
import UserInfoModal from "./../ServiceHistory/UserInfoModal";

import "../ServiceHistory/index.scss";
import "./index.scss";

export default function UserServiceHistory({ fromService }) {
  const [carToShowDetails, setCarToShowDetails] = useState(null);
  const [userToShowDetails, setUserToShowDetails] = useState(null);

  const [{ history, user }] = useStore();
  const [displayHistory, setDisplayHistory] = useState(undefined);
  const [photoModalOn, setPhotoModalOn] = useState(false);
  const [noteModalOn, setNoteModalOn] = useState(false);
  const [ratingModalOn, setRatingModalOn] = useState(false);
  const [servicePhotos, setServicePhotos] = useState([]);
  const [serviceApt, setServiceApt] = useState(undefined);
  const location = useLocation();

  useEffect(() => {
    let processedHistory = [...history];
    if (fromService) {
      processedHistory = processedHistory.filter((item) => item.archived);
    } else if (location.state?.car) {
      processedHistory = processedHistory.filter(
        (item) => item.car._id === location.state.car
      );
    } else
      processedHistory = processedHistory.sort((a, b) =>
        a.archived === true && b.archived === false ? -1 : 0
      );
    setDisplayHistory(processedHistory);
    //eslint-disable-next-line
  }, []);

  const handlePhotosClick = (service) => {
    setPhotoModalOn(true);
    setServicePhotos(service.attachedPhotos);
  };

  const handleNoteClick = (service) => {
    setNoteModalOn(true);
    setServiceApt(service);
  };

  const handleRatingClick = (service) => {
    if (user.role === "SERVICE") return;
    setServiceApt(service);
    setRatingModalOn(true);
  };

  const handleReviewSent = () => {
    setRatingModalOn(false);
  };

  return (
    <>
      {carToShowDetails && (
        <CarInfoModal
          {...carToShowDetails}
          handleClose={() => setCarToShowDetails(null)}
        />
      )}
      {userToShowDetails && (
        <UserInfoModal
          {...userToShowDetails}
          handleClose={() => setUserToShowDetails(null)}
        />
      )}
      <div
        className={
          fromService
            ? "service-history-sub-container"
            : "service-history-container"
        }
      >
        <RatingModal
          serviceItem={serviceApt}
          checkBoxState={ratingModalOn}
          oncheckBoxStateChange={(checked) => setRatingModalOn(checked)}
          onReviewSent={handleReviewSent}
        />
        <div className="service-history-list-container">
          {serviceApt ? (
            <NoteModal
              showingNoteModal={noteModalOn}
              setShowingNoteModal={setNoteModalOn}
              serviceItem={serviceApt}
              userPreview={true}
            />
          ) : (
            false
          )}
          <PhotoModal
            showingPhotoModal={photoModalOn}
            setShowingPhotoModal={setPhotoModalOn}
            userPreview={true}
            thumbnailArray={servicePhotos}
          />
          {!displayHistory ? null : displayHistory.length ? (
            displayHistory.map((item, index) => (
              <div className="service-history-item-container-new" key={index}>
                {item.archived ? (
                  <div
                    className="rating-click-wrapper"
                    onClick={() => handleRatingClick(item)}
                  >
                    <Rating
                      stars={item.review ? item.review.score : undefined}
                      readonly={true}
                    />
                  </div>
                ) : (
                  false
                )}
                <div className="service-history-item-wrap">
                  <div className="service-history-item-timestamp">
                    <span className="service-timestamp-date">
                      {DayOfMonth(item.createdAt)}
                    </span>
                    <span className="service-timestamp-month">
                      {GetMonth(item.createdAt)}
                    </span>
                    <span className="service-timestamp-year">
                      {GetYear(item.createdAt)}
                    </span>
                  </div>
                  <div
                    className={`service-history-item ${
                      item.archived ? "confirmed" : ""
                    }`}
                  >
                    {user.role === "SERVICE" && fromService ? (
                      <div
                        className="service-item-user-wrapper shop"
                        onClick={() => setUserToShowDetails(item.user)}
                      >
                        <Shop />
                        <span>
                          {item.user
                            ? `${item.user?.firstname?.toUpperCase()} ${item.user?.lastname?.toUpperCase()}`
                            : "Użytkownik usuniety"}
                        </span>
                      </div>
                    ) : (
                      <div className="service-item-user-wrapper shop">
                        <Shop />
                        <span>
                          {item.shop?.serviceName?.toUpperCase() ||
                            "Warsztat usunięty"}
                        </span>
                      </div>
                    )}
                    <div
                      className="service-item-car-wrapper"
                      onClick={() => setCarToShowDetails(item.car)}
                    >
                      <Auto />
                      <span>{item.car?.brand || "Auto"}</span>
                      <span>{item.car?.model || "usunięte"}</span>
                    </div>
                  </div>
                </div>
                <div className="service-history-item-status-bar border-bottom-radius user">
                  <div>
                    <span
                      className={`status-desc-user ${
                        item.archived ? "archived" : ""
                      }`}
                    >
                      {item.archived
                        ? "gotowy"
                        : item.shopApproved
                        ? "w serwisie"
                        : "w kolejce"}
                    </span>
                  </div>
                  <div onClick={() => handleNoteClick(item)}>
                    <span className="status-service-desc">opis</span>
                    <Text />
                  </div>
                  <div onClick={() => handlePhotosClick(item)}>
                    <span className="status-service-photos">zdjecia</span>
                    <Photos />
                  </div>
                </div>
              </div>
            ))
          ) : user.role === "SERVICE" ? (
            <div className="service-history-no-results">
              <span>brak historii napraw</span>
            </div>
          ) : (
            <div className="service-history-no-results">
              <span>brak historii aut</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
