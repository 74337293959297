import { Link } from "react-router-dom";
import mobileGroup from "./Group3.png";
import style from "./style.css";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import doc1 from "../../assets/Informacja_do_uzytkownik_Motohistorii.pdf";
import doc2 from "../../assets/Oswiadczenia_zgody_uzytkownik_Motohistorii.pdf";
import doc3 from "../../assets/Regulamin_Usługi_Motohistoria.pdf";

export default function LandingPageLayout() {
  return (
    <>
      <div id="rootLP">
        <nav>
          <Link to="/" className="nav_app_title">
            <span>moto</span>
            <span>Historia.pl</span>
          </Link>
          <div className="nav_actions_container">
            <Link to="/login" className="nav_action bg-transparent">
              Zaloguj
            </Link>
            <Link to="/register" className="nav_action">
              Zarejestruj się
            </Link>
          </div>
        </nav>
        <div className="light">
          <div className="light-frame">
            <div className="titles">
              <h1 className="nav_app_title">
                <span>Jakość</span>
                <span>,&nbsp;</span>
                <span>Przejrzystość</span>
              </h1>
              <h3>Twój samochód, Twoja historia serwisowa</h3>
            </div>

            <p>
              Z naszą aplikacją wzorowo zbudujesz historię napraw swojego auta.
              Skorzystaj z naszej bazy zaufanych mechaników, którzy razem z Tobą
              zatroszczą się o Twój samochód!
            </p>
            <div className="main_btns">
              <Link to="/register">
                <button className="main_btn-register">Zarejestruj się</button>
              </Link>
              <button className="ghost">Zobacz demo</button>
            </div>
          </div>

          <div className="bg-circle circle">
            <img src={mobileGroup} />
          </div>
          <div className="small-text-boxes">
            <div>
              <h4>Dodaj pojazd</h4>
              <p>
                Wprowadź swoje auto, aby mieć pełną kontrolę nad jego historią
                serwisową.
              </p>
            </div>
            <div>
              <h4>Wyszukaj mechanika</h4>
              <p>
                Znajdź zaufanych mechaników w swojej okolicy w kilka sekund.
              </p>
            </div>
            <div>
              <h4>Stwórz historię</h4>
              <p>
                Znajdź zaufanych mechaników w swojej okolicy w kilka sekund.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-circle circle">
          <img src={mobileGroup} />
        </div>
        <footer>
          <div>
            <Link to="/" className="nav_app_title">
              <span>moto</span>
              <span className="text-white">Historia.pl</span>
            </Link>
            <div id="socials">
              <a
                href="https://www.facebook.com/profile.php?id=61551650467936"
                target="_blank"
              >
                <Facebook />
              </a>
              <a
                href="https://www.instagram.com/motohistoria.pl"
                target="_blank"
              >
                <Instagram />
              </a>
              <a href="https://twitter.com/motohistoriapl" target="_blank">
                <Twitter />
              </a>
            </div>
          </div>
          <div className="footer-links">
            <Link to="/about">O nas</Link>
            <Link to="/products">Produkty</Link>
            <Link to={doc1} rel="noreferrer" target="_blank">
              Informacja
            </Link>
            <Link to={doc2} rel="noreferrer" target="_blank">
              Oświadczenie zgody użytkownika
            </Link>
            <Link to={doc3} rel="noreferrer" target="_blank">
              Regulamin
            </Link>
            <Link to="/contact">Kontakt</Link>
          </div>
        </footer>
      </div>
    </>
  );
}
