import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "../../../../utils/store";
import { findShopsRequest } from "../../../../requests/shop-request";

import Rating from "../../../Rating";
import { ReactComponent as Loupe } from "../../../../svgs/loupe.svg";

import "./index.scss";

export default function FindShop() {
  useEffect(() => {
    return () => {
      dispatch({ type: "updateShopList", payload: [] });
    };
    //eslint-disable-next-line
  }, []);

  const [shopName, setShopName] = useState("");
  const [{ shops }, dispatch] = useStore();
  const navigate = useNavigate();
  const { mutate } = useMutation(findShopsRequest, {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
    },
    onSuccess: (res) => {
      const newShopList = [];
      Object.keys(res.data).forEach((key) => newShopList.push(res.data[key]));
      dispatch({ type: "updateShopList", payload: newShopList });
    },
    onError: (error) => {
      dispatch({ type: "onError", payload: error });
      navigate("/response");
    },
  });

  const handleSearch = () => {
    if (shopName !== "") {
      dispatch({ type: "onLoading", payload: "loading" });
      mutate({ serviceName: shopName });
      setTimeout(() => {
        dispatch({ type: "onLoading", payload: undefined });
      }, 600);
    }
  };

  const setGlobalShop = (selectedShop) => {
    dispatch({ type: "setGlobalShop", payload: selectedShop });
    dispatch({ type: "pieMenuToggle", payload: true });
    shops[shops.indexOf(selectedShop)].picked = true;
  };

  const printCorrectReviewName = (reviewCount = 0) => {
    switch (true) {
      case reviewCount === 1:
        return `${reviewCount} opinia`;
      case reviewCount > 1 && reviewCount < 5:
        return `${reviewCount} opinie`;
      default:
        return `${reviewCount} opinii`;
    }
  };

  return (
    <div className={`find-shop-container ${!shops.length ? "no-results" : ""}`}>
      <div className="searchBoxWrapper">
        <div className="search-button" onClick={handleSearch}>
          <Loupe />
        </div>
        <input
          placeholder="Znajdź warsztat"
          onChange={(e) => setShopName(e.target.value)}
        />
      </div>
      <div className="searchResultsWrapper">
        {shops.length ? (
          shops.map((item, index) => (
            <div
              className={`searchItem ${item.picked ? "picked" : ""}`}
              key={index}
              onClick={() => setGlobalShop(item)}
            >
              <span className="find-shop-service-name">
                {item.serviceName.toUpperCase()}
              </span>
              <span className="shop-address">{`${item.address.street} ${item.address.streetNo}, ${item.address.postalCode} ${item.address.city}`}</span>
              <Rating readonly={true} stars={item.rating || null} />
              <span className="reviews">
                {printCorrectReviewName(item.reviewsCount)}
              </span>
            </div>
          ))
        ) : (
          <div className={`no-results ${!shops.length ? "show" : ""}`}>
            Brak wyników wyszukiwania
          </div>
        )}
      </div>
    </div>
  );
}
